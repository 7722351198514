import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function PostAuthHandler() {
  const { token, userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    console.log('token', token);
    console.log('userId', userId);

    if (token && userId) {
      // Store token and userId in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      console.log(userId, token);

      // Determine where to navigate based on the stored redirect path
      const postAuthRedirect = sessionStorage.getItem('postAuthRedirect') || '/';

      console.log(postAuthRedirect);
      sessionStorage.removeItem('postAuthRedirect'); // Clear the stored redirect path

      // Navigate to the desired path
      navigate(postAuthRedirect);

      window.location.reload();
    }
  }, [navigate, userId, token]);

  return (
    <div>
      <h2>Authenticating...</h2>
      {/* Optionally, add a spinner or a loading state */}
    </div>
  );
}

export default PostAuthHandler;
